import { LoadingStateEnum } from '@dmc-ng/data-access';
import { ScopedUserModel } from '@dmc-ng/data-access/user';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { organizationUsersActions } from './organization-users.actions';
import { OrganizationUsersStoreModel } from '../models/organization-user.model';

export const ORGANIZATION_USERS_FEATURE_KEY = 'organization-users';

export const organizationsUserAdapter: EntityAdapter<ScopedUserModel> =
  createEntityAdapter<ScopedUserModel>();

export const usersByOrganizationAdapter: EntityAdapter<OrganizationUsersStoreModel> =
  createEntityAdapter<OrganizationUsersStoreModel>({
    selectId: (users) => users.organizationId,
  });

export interface UsersOrganizationState extends EntityState<ScopedUserModel> {
  loading: LoadingStateEnum;
  error?: unknown;
  total?: number;
  usersByOrganization: UsersByOrganizationState;
}

export interface UsersByOrganizationState
  extends EntityState<OrganizationUsersStoreModel> {
  loading: LoadingStateEnum;
  total?: number;
  error?: unknown;
}

export const initialUsersOrganizationState: UsersOrganizationState =
  organizationsUserAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
    usersByOrganization: usersByOrganizationAdapter.getInitialState({
      loading: LoadingStateEnum.Ready,
    }),
  });

const reducer = createReducer(
  initialUsersOrganizationState,
  on(organizationUsersActions.getUsersForOneOrganization, (state) => ({
    ...state,
    usersByOrganization: {
      ...state.usersByOrganization,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(organizationUsersActions.usersRetrievedSuccessfully, (state) => ({
    ...state,
    usersByOrganization: {
      ...state.usersByOrganization,
      loading: LoadingStateEnum.Done,
    },
  })),
  on(
    organizationUsersActions.addMappedUsersForOneOrganization,
    (state, { users, total }) => ({
      ...state,
      usersByOrganization: usersByOrganizationAdapter.upsertOne(users, {
        ...state.usersByOrganization,
        total,
      }),
    }),
  ),
  on(organizationUsersActions.usersFailedToBeRetrieved, (state, { error }) => ({
    ...state,
    usersByOrganization: {
      ...state.usersByOrganization,
      loading: LoadingStateEnum.Done,
      error,
    },
  })),
  on(organizationUsersActions.getUsersForAllOrganizations, (state) => ({
    ...state,
    loading: LoadingStateEnum.Loading,
  })),
  on(
    organizationUsersActions.allUsersRetrievedSuccessfully,
    (state, { users, total }) =>
      organizationsUserAdapter.setAll(users, {
        ...state,
        total,
        loading: LoadingStateEnum.Done,
      }),
  ),
  on(
    organizationUsersActions.allUsersFailedToBeRetrieved,
    (state, { error }) => ({ ...state, error, loading: LoadingStateEnum.Done }),
  ),
);

export function organizationUsersReducer(
  state: UsersOrganizationState | undefined,
  action: Action,
): UsersOrganizationState {
  return reducer(state, action);
}
