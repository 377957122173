import { ScopedUserModel } from '@dmc-ng/data-access/user';
import { props, createActionGroup, emptyProps } from '@ngrx/store';

import { OrganizationUsersStoreModel } from '../models/organization-user.model';

export const organizationUsersActions = createActionGroup({
  source: 'Organization Users',
  events: {
    'Get Users for all organizations': props<{
      limit: number;
      offset?: number;
    }>(),
    'All Users retrieved successfully': props<{
      users: ScopedUserModel[];
      total: number;
    }>(),
    'All Users failed to be retrieved': props<{ error: unknown }>(),

    'Get Users for one organization': props<{
      organizationId: string;
      limit?: number;
      offset?: number;
    }>(),
    'Users retrieved successfully': props<{
      users: ScopedUserModel[];
      organizationId: string;
      total: number;
    }>(),
    'Users failed to be retrieved': props<{ error: unknown }>(),
    'Add  mapped Users for one Organization': props<{
      users: OrganizationUsersStoreModel;
      total: number;
    }>(),
  },
});
