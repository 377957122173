import { Injectable } from '@angular/core';
import { APIResponse, PaginatedResponse } from '@dmc-ng/data-access';
import { ScopedUserModel } from '@dmc-ng/data-access/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { organizationUsersActions } from './organization-users.actions';
import { OrganizationUsersService } from '../organization-users.service';
import { OrganizationUsersUtils } from '../utils/organization-users.utils';

@Injectable()
export class OrganizationUsersEffects {
  usersForOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationUsersActions.getUsersForOneOrganization),
      switchMap(({ organizationId, limit, offset }) =>
        this.organizationUsersService
          .getUsers(limit, offset, organizationId)
          .pipe(
            map(({ data }) =>
              organizationUsersActions.usersRetrievedSuccessfully({
                users: (data as PaginatedResponse<ScopedUserModel>).items,
                organizationId,
                total: (data as PaginatedResponse<ScopedUserModel>).total,
              }),
            ),
            catchError((error) =>
              of(organizationUsersActions.usersFailedToBeRetrieved({ error })),
            ),
          ),
      ),
    ),
  );

  mappedUsersForOneOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationUsersActions.usersRetrievedSuccessfully),
      map(({ users, organizationId, total }) =>
        organizationUsersActions.addMappedUsersForOneOrganization({
          users: OrganizationUsersUtils.convertOneUsersOrganization(
            users,
            organizationId,
          ),
          total,
        }),
      ),
    ),
  );

  usersForAllOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationUsersActions.getUsersForAllOrganizations),
      switchMap(({ limit, offset }) =>
        this.organizationUsersService.getUsers(limit, offset).pipe(
          map(({ data }) =>
            organizationUsersActions.allUsersRetrievedSuccessfully({
              users: (data as PaginatedResponse<ScopedUserModel>).items,
              total: (data as PaginatedResponse<ScopedUserModel>).total,
            }),
          ),
          catchError((error) =>
            of(organizationUsersActions.allUsersFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private organizationUsersService: OrganizationUsersService,
  ) {}
}
