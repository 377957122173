import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ORGANIZATION_USERS_FEATURE_KEY,
  UsersOrganizationState,
  organizationsUserAdapter,
  usersByOrganizationAdapter,
} from './organization-users.reducers';

export const selectOrganizationUsersState =
  createFeatureSelector<UsersOrganizationState>(ORGANIZATION_USERS_FEATURE_KEY);
const { selectEntities, selectAll } = organizationsUserAdapter.getSelectors();
const {
  selectEntities: usersByOrganizationEntities,
  selectAll: allUsersByOrganization,
} = usersByOrganizationAdapter.getSelectors();

export const selectUsersByOrganizationState = createSelector(
  selectOrganizationUsersState,
  (state) => state.usersByOrganization,
);
export const selectUsersByOrganizationEntities = createSelector(
  selectUsersByOrganizationState,
  usersByOrganizationEntities,
);
export const selectUsersByOrganization = createSelector(
  selectUsersByOrganizationState,
  allUsersByOrganization,
);
export const selectUsersByOrganizationError = createSelector(
  selectUsersByOrganizationState,
  (state) => state.error,
);
export const selectUsersByOrganizationLoading = createSelector(
  selectUsersByOrganizationState,
  (state) => state.loading,
);
export const selectUsersByOrganizationTotal = createSelector(
  selectUsersByOrganizationState,
  (state) => state.total,
);

export const selectAllUsersOrganization = createSelector(
  selectOrganizationUsersState,
  selectAll,
);
export const selectAllUsersOrganizationEntities = createSelector(
  selectOrganizationUsersState,
  selectEntities,
);

export const selectAllUsersOrganizationLoading = createSelector(
  selectOrganizationUsersState,
  (state: UsersOrganizationState) => state.loading,
);
export const selectAllUsersOrganizationError = createSelector(
  selectOrganizationUsersState,
  (state) => state.error,
);
export const selectAllUsersOrganizationTotal = createSelector(
  selectOrganizationUsersState,
  (state) => state.total,
);

export const selectAllUsersOrganizationWithPermissions = createSelector(
  selectAllUsersOrganization,
  (users) => users?.filter((u) => u.policies && u.policies.length > 0),
);
