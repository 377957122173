import { ScopedUserModel } from '@dmc-ng/data-access/user';

import { OrganizationUsersStoreModel } from '../models/organization-user.model';

export abstract class OrganizationUsersUtils {
  static convertOneUsersOrganization(users: ScopedUserModel[], organizationId: string): OrganizationUsersStoreModel {
    return {
      organizationId,
      users
    };
  }
}
